










































import { Vue, Component, Mixins } from 'vue-property-decorator'

import MyCommon from '@/mixins/common'

// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvMenu from '$ui/dv/packages/my-dv-menu'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvIcon from '$ui/dv/packages/my-dv-icon'
// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'
// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'
// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'

// @ts-ignore
import MyDvAdorn2 from '$ui/dv/packages/my-dv-adorn2'

import Map from './map.vue'

import StadiumModal from './list/stadium.vue'

@Component({
  components: {
    MyDvTitle,
    MyDvMenu,
    MyDvBox,
    MyDvIcon,
    MyDvNumber,
    MyDvText,
    MyDvBorder6,
    MyDvAdorn2,
    Map,
    StadiumModal,
  },
})
export default class extends Mixins(MyCommon) {
  stat = [
    { label: '申请装机数量', value: 1469, title: '申请装机场馆' },
    { label: '装机中数量', value: 16, title: '装机中场馆' },
    { label: '已装机数量', value: 2, title: '已装机场馆' },
    { label: '运营中数量', value: 2, title: '运营中场馆' },
  ]

  stadiumModalVisible: boolean = false
  stadiumModalTitle: string = ''
  stadiumModalParams: any = {}

  showStadiumModal(title: string) {
    this.stadiumModalTitle = title
    this.stadiumModalVisible = true
  }

  hideStadiumModal() {
    this.stadiumModalVisible = false
  }
}
