






















































































































































































































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import XEUtils from 'xe-utils'
import { Modal, VXETable } from 'vxe-table'
import 'vxe-table/lib/style.css'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
})

Vue.prototype.$XModal = VXETable.modal

Vue.use(Modal)

import MyList from '@/mixins/list'

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'
// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvRing from '$ui/dv/packages/my-dv-ring'

import TodayBuy from '../components/today_buy.vue'
import TodayGround from '../components/today_ground.vue'
import TodayPlay from '../components/today_play.vue'

import Room from '../components/room.vue'

@Component({
  components: {
    MyDvPage,
    MyDvBox,
    MyDvNumber,
    MyDvBorder6,
    MyDvTitle,
    MyDvRing,
    TodayBuy,
    TodayGround,
    TodayPlay,
    Room,
  },
})
export default class extends Mixins(MyList) {
  @Prop() stadiumId!: number

  @Prop() pageVisible!: boolean

  modalShow() {
    // @ts-ignore
    this.$refs.modal1.maximize()
  }
}
