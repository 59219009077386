





























































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import MyCommon from '@/mixins/common'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'

@Component({
  components: {
    MyDvBox,
    MyDvNumber,
  },
})
export default class extends Mixins(MyCommon) {
  @Prop() stadiumId!: number

  formData: any = {}

  async loadData() {
    const res = await this.$store.dispatch('stadium/todayBuy', {
      stadium_id: this.stadiumId,
    })

    // console.log(res);
    if (res && res.Data) {
      this.formData = res.Data
    }
  }

  mounted() {
    this.loadData()
  }
}
