



import { Vue, Component, Mixins } from 'vue-property-decorator'

import * as THREE from 'three'

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'

import { CSG } from 'three-csg-ts'

const OrbitControls = require('three-orbit-controls')(THREE)

@Component
export default class extends Mixins() {
  renderer: any

  scene: any

  camera: any

  width: number = 0
  height: number = 0

  ref1: any

  floor: any
  floor1: any

  materialArrayA: any = []
  materialArrayB: any = []
  matArrayA: any = []
  matArrayB: any = []
  matArrayC: any = []

  dummy: any = new THREE.Object3D()

  created() {}

  mounted() {
    this.ref1 = this.$refs.room
    setTimeout(() => {
      this.width = Number(
        // @ts-ignore
        window.getComputedStyle(this.ref1).width.replace('px', '')
      )
      this.height = Number(
        // @ts-ignore
        window.getComputedStyle(this.ref1).height.replace('px', '')
      )
      this.initScene()
      this.initCamera()
      this.initRender()
      this.initEvent()
      this.initControls()
      this.initLight()

      this.createWallMaterial()

      this.createFloor()

      this.createLayout()

      this.animate()
    }, 1000)
  }

  initScene() {
    this.scene = new THREE.Scene()
    const axesHelper = new THREE.AxesHelper(4500)
    this.scene.add(axesHelper)
  }

  initCamera() {
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      0.1,
      10000
    )

    // this.scene.add(this.camera)
  }

  initRender() {
    this.renderer = new THREE.WebGLRenderer()
    this.renderer.setSize(this.width, this.height)
    // document.body.appendChild(renderer.domElement)

    this.ref1.append(this.renderer.domElement)

    this.renderer.setClearColor(0x4682b4, 1.0)
  }

  initEvent() {}

  initControls() {
    const controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.camera.position.set(0, 4000, 1800)
    this.camera.lookAt(this.scene.position)
    this.camera.lookAt(0, 0, 0)
    controls.update()
  }

  initLight() {
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    directionalLight.position.set(0, 100, 0).normalize()
    this.scene.add(directionalLight)

    const ambient = new THREE.AmbientLight(0xffffff, 1)
    ambient.position.set(0, 0, 0).normalize()
    this.scene.add(ambient)
  }

  createWallMaterial() {
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //前  0xafc0ca :灰色
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //后
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //上  0xd6e4ec： 偏白色
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //下
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //左    0xafc0ca :灰色
    this.matArrayA.push(new THREE.MeshPhongMaterial({ color: 0xd6e4ec })) //右

    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0x9cb2d1 })) //前  0xafc0ca :灰色
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0x9cb2d1 })) //后  0x9cb2d1：淡紫
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0x9cb2d1 })) //上  0xd6e4ec： 偏白色
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0x9cb2d1 })) //下
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0x9cb2d1 })) //左   0xafc0ca :灰色
    this.matArrayB.push(new THREE.MeshPhongMaterial({ color: 0x9cb2d1 })) //右

    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //前  0xafc0ca :灰色
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //后  0x9cb2d1：淡紫
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //上  0xd6e4ec： 偏白色
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //下
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //左   0xafc0ca :灰色
    this.matArrayC.push(new THREE.MeshPhongMaterial({ color: 0xf7b515 })) //右
  }

  createFloor() {
    // console.log(publicPath)
    const floorGeometry = new THREE.BoxGeometry(5960, 2700, 1)
    const floorTexture = new THREE.TextureLoader().load(
      '/static/room/floor.jpg'
    )
    floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping
    floorTexture.repeat.set(10, 10)
    // console.log(floorTexture)
    const floorMaterial = new THREE.MeshBasicMaterial({
      map: floorTexture,
      side: THREE.DoubleSide,
    })
    // console.log(floorMaterial)
    this.floor = new THREE.Mesh(floorGeometry, floorMaterial)
    this.floor.position.y = -0.5
    this.floor.rotation.x = Math.PI / 2
    this.scene.add(this.floor)

    const floorGeometry1 = new THREE.BoxGeometry(1760, 1160, 1)
    const floorMaterial1 = new THREE.MeshBasicMaterial({
      map: floorTexture,
      side: THREE.DoubleSide,
    })

    this.floor1 = new THREE.Mesh(floorGeometry1, floorMaterial1)
    this.floor1.position.y = -0.5
    this.floor1.rotation.x = Math.PI / 2

    this.floor1.position.x = -3855
    this.floor1.position.z = -770

    this.scene.add(this.floor1)
  }

  animate() {
    requestAnimationFrame(this.animate)

    this.renderer.render(this.scene, this.camera)
  }

  createCubeWall(
    w: number,
    h: number,
    d: number,
    angle: any,
    material: any,
    x: number,
    y: number,
    z: number,
    isAdd: boolean = true
  ) {
    const geometry = new THREE.BoxGeometry(w, h, d)
    const cube = new THREE.Mesh(geometry, material)

    cube.position.x = x
    cube.position.y = y
    cube.position.z = z

    cube.rotation.y += angle * Math.PI

    if (isAdd) {
      this.scene.add(cube)
    }

    return cube
  }

  createGround(
    w: number = 1500,
    h: number = 710,
    d: number = 1,
    x: number,
    y: number,
    z: number,
    isAdd: boolean = true,
    groundName: string = ''
  ) {
    const groundGeometry = new THREE.BoxGeometry(w, h, d)
    const texture = new THREE.TextureLoader().load('/static/room/ground2.jpeg')
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping

    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
    })

    const ground = new THREE.Mesh(groundGeometry, material)

    ground.rotation.x = Math.PI / 2

    ground.position.x = x
    ground.position.y = y
    ground.position.z = z

    if (groundName) {
      const loader = new FontLoader()
      loader.load('helvetiker_regular.typeface.json', (font) => {
        const geometry = new TextGeometry(groundName, {
          font: font,
          size: 100,
          height: 10,
          curveSegments: 12,
          bevelEnabled: false,
          bevelThickness: 20,
          bevelSize: 8,
          bevelSegments: 5,
        })

        const textMesh1 = new THREE.Mesh(geometry)

        textMesh1.rotation.x = Math.PI / 2

        textMesh1.position.x = x
        textMesh1.position.y = y + 10
        textMesh1.position.z = z

        this.scene.add(textMesh1)
      })
    }

    if (isAdd) {
      this.scene.add(ground)
    }

    return ground
  }

  createLayout() {
    // 上北，下南，左西，右东

    // 10号场西面墙
    this.createCubeWall(10, 1000, 1150, 0, this.matArrayB, -4740, 500, -780)

    // 10号场南面墙
    this.createCubeWall(10, 1000, 1760, 1.5, this.matArrayB, -3860, 500, -190)

    // 7号场西面墙
    this.createCubeWall(10, 1000, 1540, 0, this.matArrayB, -2980, 500, 580)

    // 东面墙
    this.createCubeWall(10, 1000, 2700, 1, this.matArrayB, 2980, 500, 0)

    // 北面墙
    const wall1 = this.createCubeWall(
      10,
      1000,
      7720,
      1.5,
      this.matArrayB,
      -880,
      500,
      -1350,
      false
    )
    // 门3
    const doorGeometry3 = new THREE.BoxGeometry(100, 200, 100)
    const doorMaterial3 = new THREE.MeshNormalMaterial()
    doorMaterial3.opacity = 1.0
    doorMaterial3.transparent = true

    const door3 = new THREE.Mesh(doorGeometry3, doorMaterial3)

    door3.position.y = 100
    door3.position.z = -1350
    door3.position.x = 1800
    wall1.updateMatrix()
    door3.updateMatrix()

    const doorResult3 = CSG.subtract(wall1, door3)

    this.scene.add(doorResult3)

    // 南面墙
    const wall = this.createCubeWall(
      5960,
      1000,
      10,
      0,
      this.matArrayB,
      0,
      500,
      1350,
      false
    )

    // 门1
    const doorGeometry1 = new THREE.BoxGeometry(400, 300, 100)
    const doorMaterial1 = new THREE.MeshNormalMaterial()
    doorMaterial1.opacity = 1.0
    doorMaterial1.transparent = true

    const door1 = new THREE.Mesh(doorGeometry1, doorMaterial1)

    door1.position.y = 150
    door1.position.z = 1350
    door1.position.x = 2500
    wall.updateMatrix()
    door1.updateMatrix()

    const doorResult1 = CSG.subtract(wall, door1)
    // this.scene.add(doorResult1)

    // 门2
    const doorGeometry2 = new THREE.BoxGeometry(100, 200, 100)
    const doorMaterial2 = new THREE.MeshNormalMaterial()
    doorMaterial2.opacity = 1.0
    doorMaterial2.transparent = true

    const door2 = new THREE.Mesh(doorGeometry2, doorMaterial2)

    door2.position.y = 100
    door2.position.z = 1350
    door2.position.x = -2930

    door2.updateMatrix()

    const doorResult2 = CSG.subtract(doorResult1, door2)
    this.scene.add(doorResult2)

    // 门上边的名字
    const loader = new FontLoader()

    loader.load('/Alibaba PuHuiTi_Regular.json', (font: any) => {
      const color = 0xffbc00

      const matDark = new THREE.LineBasicMaterial({
        color: color,
        side: THREE.DoubleSide,
      })

      const matLite = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0.4,
        side: THREE.DoubleSide,
      })

      const geometry = new TextGeometry('雄峰AI羽毛球馆', {
        font: font,
        size: 80,
        height: 10,
        curveSegments: 12,
        bevelEnabled: false,
        bevelThickness: 20,
        bevelSize: 8,
        bevelSegments: 5,
      })

      const textMesh1 = new THREE.Mesh(geometry, matLite)

      textMesh1.position.y = 400
      textMesh1.position.z = 1370
      textMesh1.position.x = 2150
      this.scene.add(textMesh1)
    })

    // 10号场
    this.createGround(1500, 710, 1, -3855, 10, -770, true, '10')

    // 10号场遮光布
    this.createCubeWall(1, 300, 1050, 0, this.matArrayC, -2990, 400, -760)

    // 9号场
    this.createGround(1500, 710, 1, -2140, 10, 0, true, '9')
    // 8号场
    this.createGround(1500, 710, 1, -2140, 10, 770, true, '8')
    // 7号场
    this.createGround(1500, 710, 1, -2140, 10, -770, true, '7')

    // 7，8，9号场遮光布
    this.createCubeWall(1, 300, 2400, 0, this.matArrayC, -1370, 400, 0)

    // 6号场
    this.createGround(1500, 710, 1, -590, 10, -770, true, '6')
    // 5号场
    this.createGround(1500, 710, 1, -590, 10, 0, true, '5')
    // 4号场
    this.createGround(1500, 710, 1, -590, 10, 770, true, '4')

    // 4，5，6号墙遮光布
    this.createCubeWall(1, 300, 2400, 0, this.matArrayC, 190, 400, 0)

    // 3号场
    this.createGround(1500, 710, 1, 960, 10, -770, true, '3')

    // 2号场
    this.createGround(1500, 710, 1, 960, 10, 0, true, '2')

    // 1号场
    this.createGround(1500, 710, 1, 960, 10, 770, true, '1')

    // 1，2，3号遮光布
    this.createCubeWall(1, 300, 2400, 0, this.matArrayC, 1700, 400, 0)

    // 淋浴室
    this.createCubeWall(10, 300, 440, 0, this.matArrayB, 2140, 150, -1130)

    // 淋浴室
    this.createCubeWall(10, 300, 440, 0, this.matArrayB, 2350, 150, -1130)

    // 男卫生间
    this.createCubeWall(10, 300, 440, 0, this.matArrayB, 2560, 150, -1130)

    // 女洗手间墙壁
    this.createCubeWall(10, 300, 440, 0, this.matArrayB, 2770, 150, -1130)

    // 墙壁
    this.createCubeWall(10, 300, 860, 1.5, this.matArrayB, 2550, 150, -910)

    // 办公室
    this.createCubeWall(10, 300, 860, 1.5, this.matArrayB, 2550, 150, -710)
    this.createCubeWall(10, 300, 860, 1.5, this.matArrayB, 2550, 150, -340)

    this.createCubeWall(10, 300, 860, 1.5, this.matArrayB, 2550, 150, 700)

    this.createCubeWall(10, 300, 1410, 0, this.matArrayB, 2140, 150, 0)

    this.createCubeWall(10, 300, 1410, 0, this.matArrayB, 2140, 150, 0)
  }
}
