




























import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import MyDialog from '@/components/dialog.vue'

import MyList from '@/mixins/list'

import Detail from './stadium_detail.vue'

@Component({
  components: {
    MyDialog,
    Detail,
  },
})
export default class extends Mixins(MyList) {
  @Prop() title!: string

  @Prop() params?: any

  dispatchType: string = 'datav/aistadiumdata'

  columns: any[] = [
    { title: '场馆名称', dataIndex: 'stadium_name' },
    { title: '地址', dataIndex: 'stadium_addr' },
    { title: '片场数', dataIndex: 'ground_count' },
    { title: '注册时间', dataIndex: 'created_time' },
    { title: '操作', scopedSlots: { customRender: 'action' } },
  ]

  mounted() {
    this.loadData({}, false)
  }
}
